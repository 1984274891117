import React from "react";
import { useEffect } from "react";
import Entity from "../../../assets/dashboardImages/entity.jpg";
export default function NamedEntityRecognition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            The Power of Named Entity Recognition in AI Assistants
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            <div className="md:hidden mt-10 mb-3">
              <img
                src={Entity}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            In the vast ocean of data, identifying and extracting valuable
            information can be a daunting task. Named Entity Recognition (NER),
            powered by AI assistants, serves as a treasure map, guiding
            businesses to uncover crucial insights. In this blog post, we'll
            explore the importance of NER in AI assistants, using captivating
            examples and analogies. We'll also delve into why Effie stands out
            among other AI assistants in leveraging these strategies and provide
            a use case of NER in a healthcare setting.
          </p>
        </div>
        <div className="mt-[50px] md:mt-[85px] md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={Entity}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Significance of Named Entity Recognition
        </h1>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Discovering Key Information
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          NER enables businesses to identify and extract specific information
          from vast amounts of unstructured data. It's like having a skilled
          detective who sifts through clues to uncover the most valuable
          evidence. By recognizing and categorizing named entities such as
          names, locations, organizations, and more, businesses gain actionable
          insights for informed decision-making.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Conducting Frequency Analyses
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Similar to conducting a population census to understand demographic
          patterns, NER allows businesses to conduct frequency analyses on named
          entities. By identifying the most commonly mentioned entities,
          businesses can gain a deeper understanding of trends, preferences, and
          the overall landscape of their domain.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie - Masters of Named Entity Recognition
        </h1>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Advanced NER Capabilities
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie stands out with her advanced NER capabilities. She can analyze
          text data from various sources, including customer interactions,
          articles, and documents, to identify and extract named entities
          accurately. Effie's NER capabilities empower businesses to unlock
          valuable insights hidden within vast amounts of unstructured text.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Real-time Insights and Decision-making
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's real-time NER capabilities enable businesses to gain immediate
          insights for faster decision-making. Just as a skilled navigator
          provides real-time updates on road conditions, Effie can extract and
          present relevant named entities to assist businesses in understanding
          emerging trends, customer preferences, and market dynamics.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. Use Case: Named Entity Recognition in Healthcare
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In a healthcare setting, Effie's NER capabilities can be invaluable.
          For example, in medical research, Effie can extract and categorize
          relevant named entities such as drug names, medical conditions, or
          treatment methods from scientific articles. This allows researchers to
          identify emerging trends, conduct literature reviews efficiently, and
          make informed decisions regarding patient care and new treatments.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's NER capabilities also benefit healthcare providers by
          extracting key information from patient records, such as medical
          history, allergies, or medications. This enables healthcare
          professionals to quickly access critical information, deliver
          personalized care, and enhance patient safety.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Named Entity Recognition, facilitated by AI assistants like Effie, is
          a game-changer in uncovering valuable insights from unstructured data.
          Effie's advanced NER capabilities, real-time insights, and use case in
          healthcare demonstrate her effectiveness in leveraging these
          strategies. By harnessing Effie's power, businesses can dive deep into
          data, make informed decisions, and unlock hidden gems that drive
          success.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Discover the hidden treasures within your data with Effie's powerful
          Named Entity Recognition. Uncover insights, make informed decisions,
          and set sail for success.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          How can NER enhance customer experience?
        </h1>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Named Entity Recognition (NER) can be extremely valuable in customer
          service and support scenarios. Here's how NER can be used in this
          context:
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Customer Data Extraction
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          NER can be used to extract and categorize important customer
          information such as names, email addresses, phone numbers, and account
          numbers from customer support interactions, emails, or chat logs. By
          automatically identifying and extracting these entities, customer
          service agents can quickly access relevant customer details,
          personalize their responses, and provide more efficient and accurate
          support.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Product and Service Identification
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          NER can help in recognizing and categorizing product names, model
          numbers, or service identifiers mentioned by customers during their
          interactions. This enables customer service agents to understand the
          specific products or services being referred to and provide more
          targeted and contextually relevant support. For example, if a customer
          mentions a particular model number experiencing issues, NER can
          quickly identify the specific product and assist in troubleshooting.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. Issue Categorization and Routing
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          NER can assist in categorizing customer issues based on the named
          entities mentioned in their queries or complaints. By automatically
          identifying keywords related to specific departments, products, or
          services, NER can help route customer inquiries to the appropriate
          teams or agents for quicker resolution. This ensures that customers
          are connected with the right experts who can address their concerns
          effectively.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          4. Sentiment Analysis and Customer Feedback
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          NER can play a role in sentiment analysis by identifying and
          categorizing sentiment-bearing entities within customer feedback or
          support interactions. By recognizing named entities associated with
          positive or negative sentiments, businesses can gain insights into
          customer satisfaction levels, identify recurring issues, and take
          proactive measures to improve their products or services.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          5. Knowledge Base Enhancement
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          NER can be leveraged to identify and extract named entities from
          customer support interactions and use them to enrich the
          organization's knowledge base. By automatically recognizing relevant
          entities, businesses can capture valuable information about common
          issues, customer preferences, and FAQs. This helps in continuously
          improving the knowledge base, empowering customers with self-service
          options, and reducing redundant support requests.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In brief, NER in customer service and support enables businesses to
          extract important customer data, categorize and route inquiries,
          analyze sentiment, and enhance knowledge bases. By leveraging NER
          capabilities, businesses can provide personalized, efficient, and
          effective customer support, leading to improved customer satisfaction
          and loyalty.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
