import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import BlogList from "./pages/BlogList/BlogList";
import BlogGeneration from "./pages/Blog/BlogGeneration/Blog";
import AIinEducation from "./pages/Blog/AIinEducation/Blog";
import HyperAutomation from "./pages/Blog/HyperAutomation/Blog";
import Dentistry from "./pages/Blog/Dentistry/Blog";
import Physiotherapy from "./pages/Blog/Physiotherapy/Blog";
import PrimaryCare from "./pages/Blog/PrimaryCare/Blog";
import Optometry from "./pages/Blog/Optometry/Blog";
import HealthAndFitness from "./pages/Blog/HealthAndFitness/Blog";
import MentalHealth from "./pages/Blog/MentalHealth/Blog";
import Terms from "./pages/Terms/Terms";
import ProcessAutomation from "./pages/Blog/ProcessAutomation/Blog";
import SupplyChain from "./pages/Blog/SupplyChain/Blog";
import CustomerOnboarding from "./pages/Blog/CustomerOnboarding/Blog";
import DigitalTransformation from "./pages/Blog/DigitalTransformation/Blog";
import Cybersecurity from "./pages/Blog/Cybersecurity/Blog";
import PredictiveAnalytics from "./pages/Blog/PredictiveAnalytics/Blog";
import Personalization from "./pages/Blog/Personalization/Blog";
import CustomerFeedback from "./pages/Blog/CustomerFeedback/Blog";
import AppointmentScheduling from "./pages/Blog/AppointmentScheduling/Blog";
import CostEffective from "./pages/Blog/CostEffective/Blog";
import EfficientTriage from "./pages/Blog/EfficientTriage/Blog";
import PersonalizedCare from "./pages/Blog/PersonalizedCare/Blog";
import PrivacyConfidentiality from "./pages/Blog/PrivacyConfidentiality/Blog";
import SeamlessIntegration from "./pages/Blog/SeamlessIntegration/Blog";
import AIinHealthcare from "./pages/Blog/AIinHealthcare/Blog";
import FutureOfMentalHealth from './pages/Blog/FutureOfMentalHealth/Blog';
import NutritionalGuidance from './pages/Blog/NutritionalGuidance/Blog';
import DataPortability from './pages/Blog/DataPortability/Blog';
import DemographicInsights from './pages/Blog/DemographicInsights/Blog';
import SentimentAnalysis from './pages/Blog/SentimentAnalysis/Blog';
import NamedEntityRecognition from './pages/Blog/NamedEntityRecognition/Blog';
import SymptomChecker from './pages/Blog/SymptomChecker/Blog';
import PatientManagement from './pages/Blog/PatientManagement/Blog';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element= {<Home />} />
          <Route path="/blog" element= {<BlogList />} />
          <Route path="/blog/bloggeneration" element= {<BlogGeneration />} />
          <Route path="/blog/aieducation" element= {<AIinEducation />} />
          <Route path="/blog/hyperautomation" element= {<HyperAutomation />} />
          <Route path="/blog/dentistry" element= {<Dentistry />} />
          <Route path="/blog/physiotherapy" element= {<Physiotherapy />} />
          <Route path="/blog/primarycare" element= {<PrimaryCare />} />
          <Route path="/blog/optometry" element= {<Optometry />} />
          <Route path="/blog/health" element= {<HealthAndFitness />} />
          <Route path="/blog/mentalhealth" element= {<MentalHealth />} />
          <Route path="/blog/process" element= {<ProcessAutomation />} />
          <Route path="/blog/supplychain" element= {<SupplyChain />} />
          <Route path="/blog/customeronboarding" element= {<CustomerOnboarding />} />
          <Route path="/blog/digitaltransformation" element= {<DigitalTransformation />} />
          <Route path="/blog/cybersecurity" element= {<Cybersecurity />} />
          <Route path="/blog/predictiveanalytics" element= {<PredictiveAnalytics />} />
          <Route path="/blog/personalization" element= {<Personalization />} />
          <Route path="/blog/customerfeedback" element= {<CustomerFeedback />} />
          <Route path="/blog/appointmentscheduling" element= {<AppointmentScheduling />} />
          <Route path="/blog/costeffective" element= {<CostEffective />} />
          <Route path="/blog/efficienttriage" element= {<EfficientTriage />} />
          <Route path="/blog/personalizedcare" element= {<PersonalizedCare />} />
          <Route path="/blog/privacyconfidentiality" element= {<PrivacyConfidentiality />} />
          <Route path="/blog/seamlessintegration" element= {<SeamlessIntegration />} />
          <Route path="/blog/aiinhealthcare" element= {<AIinHealthcare />} />
          <Route path="/blog/futureofmentalhealth" element= {<FutureOfMentalHealth />} />
          <Route path="/blog/nutritionalguidance" element= {<NutritionalGuidance />} />
          <Route path="/blog/dataportability" element= {<DataPortability />} />
          <Route path="/blog/demographicinsights" element= {<DemographicInsights />} />
          <Route path="/blog/sentimentanalysis" element= {<SentimentAnalysis />} />
          <Route path="/blog/namedentityrecognition" element= {<NamedEntityRecognition />} />
          <Route path="/blog/symptomchecker" element= {<SymptomChecker />} />
          <Route path="/blog/patientmanagement" element= {<PatientManagement />} />

          <Route path="/terms" element= {<Terms/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
