import React from "react";
import { useEffect } from "react";
import Sentiment from "../../../assets/dashboardImages/sentiment.jpg";
export default function SentimentAnalysis() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            The Power of AI Assistants in Understanding Audience Emotions
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            <div className="md:hidden mt-10 mb-3">
              <img
                src={Sentiment}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            In an increasingly digital and interconnected world, understanding
            your audience's emotions and reactions is crucial for creating
            exceptional customer experiences. Sentiment analysis, powered by AI
            assistants, provides a valuable tool to keep a finger on the pulse
            of your visitors. In this blog post, we'll explore the importance of
            sentiment analysis in AI assistants, using vivid examples and
            analogies. We'll also delve into why Effie stands out among other AI
            assistants in leveraging these strategies to accurately gauge
            audience emotions and reactions.
          </p>
        </div>
        <div className="mt-[50px] md:mt-[85px] md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={Sentiment}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Significance of Sentiment Analysis
        </h1>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Unveiling Customer Sentiments
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Sentiment analysis enables businesses to uncover the emotions and
          reactions of their audience. It's like having a reliable emotional
          barometer that gauges the sentiments behind customer interactions. By
          understanding whether visitors are happy, frustrated, or indifferent,
          businesses can tailor their responses and strategies to meet their
          needs effectively.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Enhancing Customer Experiences
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Just as a skilled chef adjusts flavors to create a delightful dish, AI
          assistants with sentiment analysis can customize interactions to
          enhance customer experiences. By deciphering emotions and reactions,
          businesses can offer personalized recommendations, address concerns
          promptly, and create meaningful connections that foster loyalty and
          satisfaction.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie - Masters of Sentiment Analysis
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can provide real-time sentiment analysis for live chat
          interactions on a website. Effie's advanced AI capabilities allow her
          to analyze text data from live chat conversations and accurately gauge
          the sentiment expressed by visitors in real-time.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          When integrated with a website's live chat system, Effie can monitor
          and analyze the text messages exchanged between visitors and customer
          support agents or chatbots. She uses natural language processing
          techniques to understand the emotional tone, sentiment, and context of
          the conversations.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can detect sentiments such as positive, negative, neutral, or
          even specific emotions like happiness, frustration, or satisfaction.
          By analyzing the sentiment in real-time, Effie helps businesses
          identify and address issues promptly, offer personalized support, and
          ensure a positive customer experience.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          For example, if a customer expresses frustration or dissatisfaction
          during a live chat interaction, Effie can flag the negative sentiment
          to the customer support agent or trigger an automated response to
          address the issue promptly. This enables businesses to resolve
          customer concerns in real-time, preventing potential escalations and
          fostering customer satisfaction.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's real-time sentiment analysis for live chat interactions
          provides businesses with valuable insights into customer emotions and
          reactions, allowing them to deliver more empathetic and effective
          support. By leveraging Effie's capabilities, businesses can enhance
          customer experiences, build stronger relationships, and drive customer
          loyalty.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Advanced Sentiment Analysis Capabilities
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie sets herself apart with her advanced sentiment analysis
          capabilities. She can analyze vast amounts of textual data, including
          customer feedback, reviews, and social media posts, to accurately
          gauge the emotions and reactions of visitors. Effie's sentiment
          analysis empowers businesses to understand their audience's sentiments
          at a granular level.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Real-time Response and Engagement
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's real-time sentiment analysis allows businesses to respond
          promptly to customer emotions and reactions. Similar to an attentive
          friend who picks up on subtle cues, Effie detects shifts in sentiment
          during interactions and adapts responses accordingly. This enables
          businesses to provide empathetic support, resolve issues swiftly, and
          build trust with their audience.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. Continuous Improvement and Insights
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie goes beyond sentiment analysis by providing actionable insights
          for continuous improvement. Like a wise mentor who guides personal
          growth, Effie identifies patterns and trends in sentiment data,
          helping businesses understand customer preferences, identify pain
          points, and refine their strategies for better outcomes.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Sentiment analysis from AI assistants is a game-changer in
          understanding and responding to customer emotions and reactions.
          Effie's advanced capabilities in sentiment analysis, real-time
          response, and actionable insights make her a standout AI assistant in
          leveraging these strategies. By harnessing Effie's power, businesses
          can keep their fingers on their audience's pulse, deliver exceptional
          experiences, and build meaningful connections that drive success.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Stay ahead of the emotions and reactions of your visitors with Effie's
          robust sentiment analysis. Understand your audience's sentiments and
          unlock the door to customer-centric success.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
