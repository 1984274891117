import React from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Personalization from "../assets/industries-served/person.webp";
// import Veterinary from "../assets/industries-served/veterinary.webp";
import Process from "../assets/industries-served/process.png";
import Supply from "../assets/industries-served/supply.png";
// import Labs from "../assets/industries-served/labs.png";
import Transform from "../assets/industries-served/transform2.png";
import Security from "../assets/industries-served/security.webp";
import Feedback from "../assets/industries-served/feedback.png";
import Onboarding from "../assets/industries-served/onboarding.png";
import Predict from "../assets/industries-served/pred.webp";

export default function Domains() {
  const navigate = useNavigate();

    const navigateToProcess = () => {
      navigate("/blog/process");
    };
  
    const navigateToSupply = () => {
      navigate("/blog/supplychain");
    };
  
    const navigateToCustomerOnboarding = () => {
      navigate("/blog/customeronboarding");
    };
  
    const navigateToDigitalTransformation = () => {
      navigate("/blog/digitaltransformation");
    };
  
    const navigateToCybersecurity = () => {
      navigate("/blog/cybersecurity");
    };
  
    const navigateToPredictiveAnalytics = () => {
      navigate("/blog/predictiveanalytics");
    };
    const navigateToPersonalization = () => {
      navigate("/blog/personalization");
    };
  
    const navigateToCustomerFeedback = () => {
      navigate("/blog/customerfeedback");
    };
  return (
    
  
    <section className="text-gray-700 body-font border-t border-gray-200 font-nunito-sans ">
      <br />
      <br />
      <br />

      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            is your domain not listed?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>

        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>

        <div className="container max-w-[1240px] mx-auto">
          <div className="flex flex-col text-center w-full mb-5 md:mb-20">
            <h2 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
              If every clinic is different, why use chatbots trained with
              general data?
            </h2>
            <p className="my-3 text-lg md:text-xl text-left text-gray-700 font-nunito-sans">
              We are developing AI-based conversational assistant and brand
              interaction algorithms focused on very specific areas in that
              these algorithms are individually trained for a given industry.
              <br />
              <br />
              We even take this approach to the extreme and aim to train
              separate algorithm for every business, thereby bringing
              personalization to a new level.
            </p>
          </div>
        </div>

        <div className="hidden md:block container max-w-[1240px] mx-auto mt-4">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
            <div className="col">
            <button
                className="focus:outline-none"
                onClick={navigateToProcess}
              >
                <img src={Process} alt="Process" className="img-fluid" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
              
            </div>
            <div className="col">
            <button
                className="focus:outline-none"
                onClick={navigateToSupply}
              >
                <img src={Supply} alt="Supply" className="img-fluid" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
              
            </div>
            <div className="col">
            <button
                className="focus:outline-none"
                onClick={navigateToCustomerOnboarding}
              >
                <img src={Onboarding} alt="Onboarding" className="img-fluid" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
              
            </div>
            <div className="col">
            <button
                className="focus:outline-none"
                onClick={navigateToDigitalTransformation}
              >
                <img src={Transform} alt="Transform" className="img-fluid" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
              
            </div>
          </div>
          <br />
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4">
            <div className="col">
            <button
                className="focus:outline-none"
                onClick={navigateToCybersecurity}
              >
                <img src={Security} alt="Security" className="img-fluid" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
              
            </div>
            <div className="col">
               <button
                className="focus:outline-none"
                onClick={navigateToPredictiveAnalytics}
              >
                <img src={Predict} alt="Predict" className="img-fluid" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
              
            </div>
            <div className="col">
            <button
                className="focus:outline-none"
                onClick={navigateToPersonalization}
              >
                <img
                src={Personalization}
                alt="Personalization"
                className="img-fluid"
              />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
              
            </div>
            <div className="col">
               <button
                className="focus:outline-none"
                onClick={navigateToCustomerFeedback}
              >
                <img src={Feedback} alt="Feedback" className="img-fluid" />
                <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
              </button>
              
            </div>
          </div>
        </div>

        <div className="md:hidden relative z-10 mx-auto mt-2">
          <Carousel
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={3000}
            swipeable={true}
            emulateTouch={true}
            dynamicHeight={true}
          >
            <div>
              <img src={Transform} alt="Transform" className="img-fluid" />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                    onClick={navigateToDigitalTransformation}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
            </div>
            <div>
              <img src={Process} alt="Process" className="img-fluid" />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                    onClick={navigateToProcess}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
            </div>
            <div>
              <img src={Supply} alt="Supply" className="img-fluid" />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                    onClick={navigateToSupply}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
            </div>
            <div>
              <img src={Onboarding} alt="Onboarding" className="img-fluid" />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                    onClick={navigateToCustomerOnboarding}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
            </div>
            <div>
              <img src={Security} alt="Security" className="img-fluid" />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                    onClick={navigateToCybersecurity}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
            </div>
            <div>
              <img src={Predict} alt="Predict" className="img-fluid" />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                    onClick={navigateToPredictiveAnalytics}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
            </div>
            <div>
              <img
                src={Personalization}
                alt="Personalization"
                className="img-fluid"
              />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                    onClick={navigateToPersonalization}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
            </div>
            <div>
              <img src={Feedback} alt="Feedback" className="img-fluid" />
              <div className="absolute bottom-1 right-3">
                  <button
                    className="mt-3 bg-transparent hover:bg-pcShade5 font-bold inline-flex items-center text-sm text-white p-2 rounded font-nunito-sans"
                    onClick={navigateToCustomerFeedback}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
            </div>
          </Carousel>
        </div>
      </div>
      <br />
      <br />
    </section>
  );
}
