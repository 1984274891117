import React from "react";
import { useEffect } from "react";
import PatientManagement1 from "../../../assets/dashboardImages/patientmanagement.jpg";
export default function PatientManagement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            Effie: Your All-in-One Patient Management Companion
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            <div className="md:hidden mt-10 mb-3">
              <img
                src={PatientManagement1}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            In the dynamic landscape of healthcare, efficient patient management
            is crucial for providing superior care. AI assistants, like Effie,
            play a transformative role in streamlining patient management
            processes. In this blog post, we will explore the significance of
            patient management with AI assistants, highlighting how Effie stands
            out from other AI assistants in leveraging these strategies. Get
            ready to discover how Effie consolidates patient status, details,
            conversations, requirements, and appointments into a single,
            comprehensive platform.
          </p>
        </div>
        <div className="mt-[50px] md:mt-[85px] md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={PatientManagement1}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Importance of AI-Powered Patient Management
        </h1>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Enhancing Efficiency and Organization
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI assistants simplify patient management by centralizing essential
          information in one place. They enable healthcare providers to access
          patient details, appointments, and conversations seamlessly. This
          consolidation eliminates the need for multiple disjointed systems,
          leading to streamlined workflows, reduced administrative burden, and
          improved efficiency.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Personalizing Patient Care
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Efficient patient management facilitates personalized care. AI
          assistants, like Effie, provide healthcare professionals with
          comprehensive patient profiles, including medical history, treatment
          plans, a forum where clinicians can discuss the status of a patient
          and best treatment options and preferences. This enables clinicians to
          deliver tailored care, ensuring that patients' unique needs and
          requirements are met.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie - Your All-in-One Patient Management Companion
        </h1>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Unified Patient Dashboard
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie differentiates itself by offering a unified patient dashboard
          where healthcare providers can access all relevant patient
          information. From demographic details to medical records, ongoing
          conversations, appointments, Effie consolidates everything in a
          user-friendly interface. This eliminates the need for toggling between
          multiple systems or searching for scattered information.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Conversational Insights
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie leverages advanced Natural Language Processing (NLP)
          capabilities to analyze patient conversations. By understanding
          context and extracting key insights, Effie provides healthcare
          providers with valuable information to support decision-making.
          Effie's ability to capture and interpret conversation data enables
          providers to better understand patients' concerns, preferences, and
          treatment progress.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. Intelligent Appointment Management
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie simplifies appointment management by allowing patients to
          schedule, reschedule, or cancel appointments directly through the AI
          assistant. Effie's integration with scheduling systems ensures
          real-time updates and provides healthcare providers with a centralized
          view of appointment statuses. This reduces administrative overhead and
          enhances appointment coordination.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Let's explore how Effie can be applied in physiotherapy, mental
          health, and dentistry, showcasing its versatility in different
          healthcare domains.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie in Physiotherapy: Comprehensive Rehabilitation Support
        </h1>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can assist physiotherapists in managing their patients'
          rehabilitation journeys effectively. Here's how:
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Exercise Reminders and Tracking
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can send automated reminders to patients, prompting them to
          perform their prescribed exercises at home. Effie can also track
          patients' exercise adherence and progress, allowing physiotherapists
          to monitor their patients remotely and provide timely feedback.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Symptom Assessments
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's symptom assessment capabilities enable patients to report any
          changes or concerns regarding their physical condition. Effie can
          collect information on pain levels, mobility limitations, or other
          relevant symptoms, allowing physiotherapists to evaluate progress and
          adjust treatment plans accordingly.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. Education and Resources
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can provide patients with educational materials, instructional
          videos, and resources related to their specific condition or
          rehabilitation goals. This empowers patients to gain knowledge, follow
          their treatment plans correctly, and actively participate in their
          recovery process.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie in Mental Health: Supportive Mental Well-being Companion
        </h1>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can play a valuable role in mental health settings by providing
          support and resources to individuals seeking mental well-being. Here's
          how Effie can assist:
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Emotional Support
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can engage in empathetic conversations and provide emotional
          support to individuals experiencing mental health challenges. By using
          NLP techniques, Effie can understand and respond appropriately to
          individuals' emotional needs, offering a compassionate listening ear.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Symptom Monitoring
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can regularly check in with individuals, asking about their
          emotional state, sleep patterns, stress levels, or other relevant
          symptoms. This enables Effie to track changes over time and alert
          mental health professionals when intervention or additional support
          may be required.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. Self-Care Strategies
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can offer personalized self-care strategies and coping
          techniques based on individuals' reported symptoms and concerns. This
          can include mindfulness exercises, breathing techniques, guided
          meditations, or other evidence-based practices that promote mental
          well-being
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie in Dentistry: Streamlined Dental Care Management
        </h1>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can streamline dental care management, improving patient
          experience and facilitating efficient dental workflows. Here's how
          Effie can support dentists:
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Appointment Scheduling
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can handle appointment scheduling and reminders, allowing
          patients to book, reschedule, or cancel dental appointments
          conveniently. Effie can also provide patients with pre-appointment
          instructions to ensure they are well-prepared.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Oral Health Education
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can provide patients with oral health education, offering tips
          on proper brushing techniques, flossing, and maintaining good dental
          hygiene. Effie can also answer common dental-related questions,
          empowering patients to take proactive care of their oral health.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3.Post-Treatment Follow-up
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can engage in post-treatment follow-up conversations with
          patients, checking on their recovery progress, and addressing any
          concerns or complications they may be experiencing. Effie can provide
          self-care instructions and guidance for post-treatment oral hygiene.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's adaptability allows it to cater to various healthcare domains,
          including physiotherapy, mental health, and dentistry. By leveraging
          its capabilities in patient engagement, symptom monitoring, education,
          and appointment management, Effie enhances patient care experiences
          and supports healthcare professionals in delivering effective and
          personalized treatments.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's distinct approach to patient management consolidates patient
          information, conversations, requirements, and appointments into a
          single, accessible platform. By leveraging Effie's unified dashboard,
          conversational insights, and intelligent appointment management,
          healthcare providers can streamline workflows, deliver personalized
          care, and improve patient outcomes.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie revolutionizes patient management by offering a comprehensive,
          all-in-one platform for healthcare providers. With Effie's unified
          patient dashboard, clinicians can effortlessly access patient details,
          conversations, requirements, and appointments, simplifying
          administrative tasks and enhancing patient care. Embrace the power of
          Effie's patient management capabilities to elevate your healthcare
          practice and deliver exceptional patient experiences.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
