import React, { useState, useEffect } from "react";
import { Menu, Close } from "react-ionicons";
import { useNavigate } from "react-router-dom";
// import Effie from "../assets/img/effie-logo-2.png";
// import Effie2 from "../assets/logo/effie_logo2.png";
// import Effie3 from "../assets/illustrations/old-logo.png";
import EffieWhite from "../assets/logo/effie_logo2-white.png";
import env from "../env";

export default function NavbarHome() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isNavbarTransparent, setNavbarTransparent] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setNavbarTransparent(!isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

    const navigateToInsights = () => {
      navigate("/blog");
    };

  const scrollToDemo = () => {
    setMenuOpen(!isMenuOpen);
    const targetElement = document.querySelector("#demo");
    if (targetElement) {
      const offsetTop = (targetElement.getBoundingClientRect().top) - 80;
      window.scrollBy({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const scrollToServices = () => {
    setMenuOpen(!isMenuOpen);
    const targetElement = document.querySelector("#services");
    if (targetElement) {
      const offsetTop = (targetElement.getBoundingClientRect().top) - 80;
      window.scrollBy({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };



  const scrollToContact = () => {
    setMenuOpen(!isMenuOpen);
    const targetElement = document.querySelector("#contact");
    if (targetElement) {
      const offsetTop = (targetElement.getBoundingClientRect().top) - 80;
      window.scrollBy({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };



  const dashboardLogin = () => {
    const apiUrl = env.dashboard_uri;
    const loginUrl = `${apiUrl}/auth/signin`;
    window.open(loginUrl, "_blank");
  };
  

  return (
    <nav
      className={`fixed z-30 flex justify-between  
                      items-center w-[100%] mx-auto 
                      font-nunito-sans bg-sc1Shade5 min-h-[11vh] 
                      ${isNavbarTransparent ? "bg-opacity-0" : ""}`}
    >
      <div className=" ml-14">
        <img className="w-40 cursor-pointer" src={EffieWhite} alt="Effie2" />
      </div>
      <div
        className={`md:bg-transparent md:ml-110 bg-sc1Shade5 nav-links duration-500 md:static fixed z-30 text-white md:min-h-fit min-h-[30vh] right-0 ${
          isMenuOpen ? "top-[8.7%]" : "top-[-100%]"
        } md:w-auto w-full flex flex-col items-center px-5 ${
          isNavbarTransparent ? "bg-opacity-90" : ""
        }`}
      >
        <ul className="mt-3  mb-2 flex md:flex-row font-bold text-center text-lg flex-col md:gap-[4vw] gap-4 justify-center">
        <li>
            <button
              className="hover:bg-pcShade0 p-1 md:w-[160%] rounded font-nunito-sans focus:outline-none"
              onClick={scrollToServices}
            >
              Services
            </button>
          </li>
          <li>
            <button
              className="hover:bg-pcShade0 p-1 md:w-[160%] rounded font-nunito-sans focus:outline-none"
              onClick={scrollToDemo}
            >
              Demo
            </button>
          </li>
          <li>
            <button
              className="hover:bg-pcShade0 p-1 md:w-[160%] rounded font-nunito-sans focus:outline-none"
              onClick={navigateToInsights}
            >
              Insights
            </button>
          </li>
          <li>
            <button
              className="hover:bg-pcShade0 p-1 md:w-[160%] rounded font-nunito-sans focus:outline-none"
              onClick={scrollToContact}
            >
              Contact
            </button>
          </li>
          <li>
            <button
              onClick={dashboardLogin}
              className="md:hidden bg-pcShade0 hover:bg-pcShade5 text-center md:w-[160%] text-lg font-bold text-white pl-2 pr-2 rounded font-nunito-sans focus:outline-none"
            >
              Sign In
            </button>
            <button
              onClick={dashboardLogin}
              className="hidden p-1 md:block bg-pcShade0 hover:bg-pcShade5 text-center md:w-[160%] text-lg font-bold text-white rounded font-nunito-sans focus:outline-none"
            >
              Sign In
            </button>
          </li>
        </ul>
      </div>
      <div className="flex items-center gap-6">
        {isMenuOpen ? (
          <Close
            color={"#ffffff"}
            height="32px"
            width="32px"
            onClick={toggleMenu}
            className="cursor-pointer md:hidden mr-4"
          />
        ) : (
          <Menu
            color={"#ffffff"}
            height="32px"
            width="32px"
            onClick={toggleMenu}
            className="cursor-pointer md:hidden mr-4"
          />
        )}
      </div>
    </nav>
  );
}
