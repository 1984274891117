import React from "react";
import { useEffect } from "react";
import Demography from "../../../assets/dashboardImages/demography2.jpg";
export default function DemographicInsights() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            How AI Assistants Unveil Demographic Insights for Targeted
            Strategies
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            <div className="md:hidden mt-10 mb-3">
              <img
                src={Demography}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            In today's competitive landscape, understanding your audience is the
            key to success. Demographic insights are invaluable for tailoring
            strategies that resonate with your target market. Enter AI
            assistants, the game-changers that go beyond basic interactions. In
            this blog post, we'll explore the importance of obtaining
            demographic insights from AI assistants and how they empower
            businesses to make data-driven decisions.
          </p>
        </div>
        <div className="mt-[50px] md:mt-[85px] md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={Demography}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Power of Demographic Insights
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data portability empowers users by giving them more control over their
          personal information. It enables individuals to choose the services
          that best meet their needs, without the fear of being locked into a
          particular platform. When users have the freedom to move their data,
          they can explore different options, fostering healthy competition and
          innovation among service providers.
        </p>
        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Understanding Preferences and Behaviors
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Demographic insights provide a window into the minds of your audience.
          By analyzing factors such as age, gender, location, and interests, you
          gain valuable knowledge about what drives their preferences and
          behaviors. This knowledge allows you to tailor your strategies,
          messaging, and offerings to align with their specific needs and
          desires.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Personalized Customer Experiences
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Demographic insights enable you to create personalized customer
          experiences that resonate with individuals on a deeper level. Just
          like a skilled sommelier who curates a wine selection based on a
          diner's taste preferences, AI assistants analyze data to deliver
          tailored recommendations, offers, and content. This personalization
          builds stronger connections, boosts engagement, and increases customer
          loyalty.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie - The AI Assistant Revolutionizing Demographic Insights
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data portability facilitates smooth transitions between platforms.
          Imagine switching from one LLM-powered writing tool to another and
          being able to carry over your preferred writing style, vocabulary, and
          personalized settings effortlessly. Data portability ensures that
          users can seamlessly migrate their data, preserving their preferences
          and minimizing disruptions to their workflow.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Effie's Advanced Analytical Capabilities
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie stands out among AI assistants with her sophisticated analytical
          capabilities. She not only collects and processes demographic data but
          also uncovers underlying patterns and trends. Effie transforms raw
          data into actionable insights, providing businesses with a
          comprehensive understanding of their audience's demographics.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Seamless Integration and Data Accessibility
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie takes the hassle out of data analysis by seamlessly integrating
          with various platforms and systems. She consolidates data from
          multiple sources, breaking down silos and providing a holistic view of
          demographics. Effie's user-friendly interface and intuitive dashboards
          make demographic insights easily accessible, empowering businesses to
          make informed decisions in real-time.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. AI-Powered Predictive Analytics
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie goes beyond descriptive analytics. With her AI-powered
          predictive capabilities, she forecasts future trends and behaviors
          based on demographic insights. Just like a seasoned fortune teller,
          Effie helps businesses anticipate changes in their target audience,
          enabling proactive strategies that stay one step ahead of the
          competition.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Demographic insights obtained from AI assistants are essential for
          businesses aiming to understand their audience and tailor strategies
          accordingly. By leveraging these insights, you can create personalized
          experiences, drive engagement, and foster long-term customer loyalty.
          Effie, with her advanced analytical capabilities, seamless
          integration, and predictive analytics, stands at the forefront of this
          revolution, enabling businesses to unlock the true potential of
          demographic insights.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Embrace the power of AI assistants and let Effie guide you towards a
          deeper understanding of your audience. With demographic insights in
          your arsenal, you'll be equipped to shape strategies that make a
          lasting impact.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie is designed to provide real-time demographic insights
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie is designed to provide real-time demographic insights for
          different industries. Effie's advanced analytical capabilities and
          AI-powered algorithms allow her to collect, analyze, and interpret
          data from various sources in real-time, providing businesses with
          up-to-date demographic insights.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can adapt to different industries and their specific needs.
          Whether you're in the retail sector, hospitality, e-commerce, or any
          other industry, Effie can leverage demographic data to help you
          understand your audience better. For example:
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Retail
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can analyze demographic data to identify target customer
          segments, understand their preferences, and optimize product
          assortments and marketing strategies accordingly. This can lead to
          more effective promotions, personalized recommendations, and improved
          customer experiences.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Hospitality
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can provide insights into the demographics of your guests,
          enabling you to tailor your services and amenities to meet their
          specific needs and preferences. This can include personalized offers,
          customized experiences, and targeted marketing campaigns.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. E-commerce
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie can analyze demographic data to segment your customer base,
          identify high-value customer groups, and optimize your online store's
          user experience. This can involve personalized product
          recommendations, targeted advertisements, and streamlined checkout
          processes.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's real-time demographic insights empower businesses across
          industries to make data-driven decisions, adapt their strategies, and
          enhance customer engagement. By leveraging Effie's capabilities,
          businesses can stay ahead of the competition and deliver experiences
          that resonate with their target audience.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
