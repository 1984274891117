import React, { useState, useEffect } from "react";
import { Menu, Close } from "react-ionicons";
import { useNavigate } from "react-router-dom";
import EffieWhite from "../assets/logo/effie_logo2-white.png";
import env from "../env";

export default function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isNavbarTransparent, setNavbarTransparent] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setNavbarTransparent(!isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const navigateToInsights = () => {
    navigate("/blog");
  };


  const dashboardLogin = () => {
    const apiUrl = env.dashboard_uri;
    const loginUrl = `${apiUrl}/auth/signin`;
    window.open(loginUrl, "_blank");
  };

  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };


  return (
    <nav
      className={`fixed top-0 z-30 flex justify-between  
                      items-center  w-[100%] mx-auto 
                      font-nunito-sans bg-sc1Shade5 min-h-[11vh] 
                      ${isNavbarTransparent ? "bg-opacity-100" : ""}`}
    >
      <div className=" ml-14">
        <img className="w-40 cursor-pointer" src={EffieWhite} alt="Effie2" />
      </div>
      <div
        className={`md:ml-20 bg-sc1Shade5 nav-links duration-500 md:static fixed z-30 text-white md:min-h-fit min-h-[30vh] right-0 ${
          isMenuOpen ? "top-[8.7%]" : "top-[-100%]"
        } md:w-auto w-full flex flex-col items-center px-5 ${
          isNavbarTransparent ? "bg-opacity-100" : ""
        }`}
      >
        <ul className="mt-3 mb-2 flex md:flex-row font-bold text-center text-lg flex-col md:gap-[4vw] gap-4 justify-center">
          <li>
            <button
              className="hover:bg-pcShade0 md:w-[160%] p-1 rounded font-nunito-sans focus:outline-none"
              onClick={navigateToHome}
            >
              Home
            </button>
          </li>
          <li>
            <button
              className="hover:bg-pcShade0 md:w-[160%] p-1 rounded font-nunito-sans focus:outline-none"
              onClick={navigateToInsights}
            >
              Insights
            </button>
          </li>
          <li>
            <button
              onClick={dashboardLogin}
              className="md:hidden bg-pcShade0 hover:bg-pcShade5 text-center md:w-[140%] text-lg font-bold text-white pl-2 pr-2 p-1 rounded font-nunito-sans"
            >
              Sign In
            </button>
            <button
              onClick={dashboardLogin}
              className="hidden md:block bg-pcShade0 hover:bg-pcShade5 text-center md:w-[140%] text-lg font-bold text-white p-1 rounded font-nunito-sans"
            >
              Sign In
            </button>
          </li>
        </ul>
      </div>
      <div className="flex items-center gap-6">
        {isMenuOpen ? (
          <Close
            color={"#ffffff"}
            height="32px"
            width="32px"
            onClick={toggleMenu}
            className="cursor-pointer md:hidden mr-4"
          />
        ) : (
          <Menu
            color={"#ffffff"}
            height="32px"
            width="32px"
            onClick={toggleMenu}
            className="cursor-pointer md:hidden mr-4"
          />
        )}
      </div>
    </nav>
  );
}
