import React from "react";
import { useEffect } from "react";
import Symptom from "../../../assets/dashboardImages/symptom.jpg";
export default function SymptomChecker() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            Empowering Healthcare with Intelligent Symptom Checkers
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            <div className="md:hidden mt-10 mb-3">
              <img
                src={Symptom}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            In the rapidly evolving world of healthcare, harnessing the power of
            AI assistants equipped with Natural Language Processing (NLP)
            symptom checkers can revolutionize patient care. In this blog post,
            we'll explore the importance of obtaining symptom checker
            information from AI assistants during visitor interactions. Through
            captivating examples and analogies, we'll highlight why Effie stands
            out among other AI assistants in leveraging these strategies.
            Discover how Effie empowers clinical staff with real-time patient
            updates, facilitating quicker assessments and superior care.
          </p>
        </div>
        <div className="mt-[50px] md:mt-[85px] md:ml-auto xl:ml-auto col-span-1 md:w-[50%] text-center">
          <img
            src={Symptom}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          The Significance of AI Symptom Checkers
        </h1>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Enabling Patient Self-Assessment
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          AI-powered NLP symptom checkers empower patients to self-assess their
          symptoms before seeking professional medical advice. Similar to a
          reliable compass in unfamiliar terrain, these tools provide users with
          guidance and initial insights into potential health conditions based
          on their reported symptoms.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Streamlining Clinical Workflows
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          By integrating AI symptom checkers into healthcare systems, clinical
          staff can receive real-time updates on patients' reported symptoms.
          This streamlines workflows and facilitates quicker assessments,
          allowing healthcare professionals to focus on critical cases and
          provide more efficient and timely care.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Effie - Pioneering Intelligent Symptom Checkers
        </h1>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Advanced NLP Capabilities
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie differentiates herself with advanced NLP capabilities, enabling
          accurate understanding and interpretation of patient-reported
          symptoms. Effie's sophisticated algorithms analyze the data provided
          by visitors, extracting key symptoms and relevant medical information
          to support clinical decision-making.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Real-Time Patient Updates
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie offers real-time updates to clinical staff, providing them with
          comprehensive symptom information reported by patients. This empowers
          healthcare professionals with timely insights, enabling them to
          prioritize patients and make informed decisions regarding further
          assessments, treatments, and referrals.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. Use Case: Effie in Emergency Medicine
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's intelligent symptom checker plays a crucial role in emergency
          medicine. Suppose a patient visits a hospital's website and interacts
          with Effie, describing symptoms such as severe chest pain and
          shortness of breath. Effie's NLP capabilities quickly identify these
          symptoms as potential indicators of a cardiac emergency. Effie then
          alerts the medical staff in real-time, enabling them to initiate
          emergency protocols, prepare necessary resources, and provide
          immediate life-saving interventions.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's distinctive approach to intelligent symptom checking ensures
          that clinical staff receive real-time updates, facilitating quicker
          assessments and superior care. By leveraging Effie's advanced NLP
          capabilities, healthcare providers can optimize their workflows,
          enhance patient outcomes, and deliver patient-centered care.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          In the age of AI-powered healthcare, intelligent symptom checkers like
          Effie play a vital role in empowering clinical staff with real-time
          patient updates. By combining advanced NLP capabilities with seamless
          integration into clinical workflows, Effie revolutionizes the way
          healthcare professionals assess and care for patients. Embrace the
          power of Effie's distinctive approach and unlock a new era of
          efficient, patient-centered healthcare.
        </p>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Unlock the potential of intelligent symptom checkers with Effie.
          Empower clinical staff with real-time updates, streamline assessments,
          and provide superior care
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          How Effie symptom checker can be used in clinical workflows?
        </h1>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's symptom checkers provide valuable assistance to clinicians in
          several ways:
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          1. Efficient Triage
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's symptom checkers can help clinicians in triaging patients more
          efficiently. By collecting patient-reported symptoms and analyzing
          them using intelligent algorithms, Effie provides clinicians with a
          preliminary assessment of the severity and urgency of the patient's
          condition. This helps clinicians prioritize patients and allocate
          appropriate resources accordingly.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          2. Decision Support
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's symptom checkers act as a valuable decision support tool for
          clinicians. By considering the reported symptoms and applying medical
          knowledge, Effie generates potential diagnoses or conditions that
          align with the symptoms. This assists clinicians in considering a
          wider range of possibilities and aids in the diagnostic process.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          3. Comprehensive Patient Information
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie allows clinicians to access comprehensive patient information
          during symptom assessments. By retrieving relevant medical history,
          medication records, and previous diagnoses, Effie ensures that
          clinicians have a holistic view of the patient's health status. This
          enables more informed decision-making and reduces the risk of
          overlooking important details.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          4. Real-Time Updates
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie provides clinicians with real-time updates on patient-reported
          symptoms. As patients interact with Effie and provide new information,
          clinicians receive immediate notifications. These updates enable
          clinicians to stay informed about changes in symptoms and respond
          promptly, ensuring that patients receive timely care.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          5. Streamlined Workflows
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's integration into existing clinical workflows streamlines the
          process of symptom assessment. By automating the initial data
          collection and analysis, Effie reduces the time and effort required
          from clinicians. This allows clinicians to focus on more complex cases
          and spend quality time with patients who require their expertise.
        </p>

        <h2 className="md:mt-5 text-sc1Shade4 text-xl font-bold md:text-xl my-2 font-nunito-sans">
          6. Population Health Insights
        </h2>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Effie's symptom checkers contribute to population health analysis and
          insights. By aggregating and anonymizing patient-reported symptoms,
          Effie can identify patterns and trends at a broader scale. This
          information can be utilized by clinicians, researchers, and healthcare
          organizations to understand disease prevalence, track outbreaks, and
          inform public health initiatives.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Overall, Effie's symptom checkers empower clinicians by providing
          efficient triage, decision support, comprehensive patient information,
          real-time updates, streamlined workflows, and population health
          insights. By leveraging Effie's capabilities, clinicians can enhance
          their diagnostic accuracy, optimize their workflow efficiency, and
          deliver superior care to their patients.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
