import React from "react";
import { useEffect } from "react";
import Portability from "../../../assets/dashboardImages/portability.jpg";
export default function DataPortability() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="max-w-[1240px] mx-auto p-3 mt-20 md:mt-40 md:flex gap-14 font-nunito-sans">
        <div className="col-span-2 flex flex-col justify-center md:w-full">
          <h1 className="uppercase text-sc1Shade4 text-center text-3xl md:text-4xl font-bold my-2 mb-4 font-nunito-sans">
            Data Portability in LLM and Why It Matters
          </h1>
          <p className="my-1 md:my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
            <div className="md:hidden mt-10 mb-3">
              <img
                src={Portability}
                alt="healthcare chatbot"
                className="md:hidden inline w-100"
              />
            </div>
            In the digital age, data has become the lifeblood of our online
            interactions. Whether it's social media posts, personal documents,
            or transaction histories, our digital footprints are scattered
            across various platforms. But what happens when we want to move our
            data from one platform to another seamlessly? This is where data
            portability comes into play. In this blog post, we will explore what
            data portability means in the context of Large Language Models
            (LLMs) and why it matters for users.
          </p>
        </div>
        <div className="mt-[50px] md:mt-[85px] md:ml-auto xl:ml-auto col-span-1 md:w-[40%] text-center">
          <img
            src={Portability}
            alt="ai transforming healthcare"
            className="hidden md:block inline w-full"
          />
        </div>
      </div>

      <div className="max-w-[1240px] mb-10 mx-auto p-3 font-nunito-sans justify-center md:w-full">
        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          User Empowerment
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data portability empowers users by giving them more control over their
          personal information. It enables individuals to choose the services
          that best meet their needs, without the fear of being locked into a
          particular platform. When users have the freedom to move their data,
          they can explore different options, fostering healthy competition and
          innovation among service providers.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Seamless Transitions
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data portability facilitates smooth transitions between platforms.
          Imagine switching from one LLM-powered writing tool to another and
          being able to carry over your preferred writing style, vocabulary, and
          personalized settings effortlessly. Data portability ensures that
          users can seamlessly migrate their data, preserving their preferences
          and minimizing disruptions to their workflow.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Personalization and Customization
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          With data portability, users can enjoy consistent personalization
          across platforms. LLMs learn from user interactions and adapt to
          individual preferences. By transferring data between LLM-enabled
          services, users can maintain their personalized experiences,
          benefiting from tailored recommendations, improved productivity, and
          enhanced user satisfaction.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Privacy and Security
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data portability can enhance privacy and security. When users have
          control over their data, they can ensure its protection by choosing
          platforms with robust security measures. Additionally, data
          portability encourages transparency, as users become more aware of how
          their data is handled and shared. This fosters a sense of trust
          between users and service providers.
        </p>

        <h1 className="text-sc1Shade4 text-2xl md:text-3xl font-bold my-2 font-nunito-sans">
          Collaboration and Innovation
        </h1>
        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data portability fuels collaboration and innovation. When users have
          the ability to share their data across platforms, developers can build
          upon existing knowledge and create new services and applications. This
          stimulates the development of interconnected ecosystems where
          different tools and services seamlessly integrate, leading to enhanced
          user experiences and novel solutions.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          Data portability in LLMs empowers users, enabling them to retain
          control over their personal information while enjoying a consistent
          and personalized experience across platforms. It fosters seamless
          transitions, facilitates personalization, enhances privacy and
          security, and fuels collaboration and innovation. As data continues to
          play a crucial role in our digital lives, data portability becomes
          increasingly important, ensuring that users can make informed choices,
          leverage the potential of LLMs, and shape their online experiences
          according to their preferences.
        </p>

        <p className="my-3 text-lg md:text-xl text-justify text-gray-700 font-nunito-sans">
          By prioritizing data portability, developers and service providers can
          empower users, foster trust, and contribute to a more open and
          user-centric digital landscape.
        </p>
      </div>
      <div className="container max-w-[1240px] mx-auto">
        <div className="flex flex-col text-center w-full mb-5 md:mb-20">
          <h2 className="text-base uppercase text-sc1Shade4 font-nunito-sans tracking-widest font-medium title-font mb-1">
            want to know more about use cases of ai?
          </h2>
          <h1 className="text-3xl uppercase font-nunito-sans font-bold text-sc1Shade4">
            design LLM model for your domain using your own data
          </h1>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex flex-wrap mb-5">
            <div className="flex-1 mb-3 mr-2 md:mb-0 md:mr-2">
              <input
                className="bg-gray-200 text-gray-900 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full md:w-60"
                type="email"
                placeholder="Enter your Email"
                defaultValue=""
              />
            </div>
            <div className="flex-2 md:flex-2">
              <button
                type="submit"
                className="uppercase text-base font-bold tracking-wide bg-pcShade0 hover:bg-pcShade5 text-gray-100 p-3 rounded-lg focus:outline-none focus:shadow-outline w-full"
              >
                Send US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
